

import {IonContent, IonItem, IonLabel, IonPage} from '@ionic/vue';
import {execute} from "@/mixins/LoadingMixin";
import {computed, defineComponent, ref} from "vue";
import {JourneyTemplate} from "@/models/JourneyTemplateModel";
import {getCompleteTemplates} from "@/services/TemplateService";
import { formatDate } from '@/utils/DatesUtil';
import {getJourneySet, updateJourneySet} from "@/services/JourneySetService";
import router from "@/router";
import {useStore} from "vuex";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default defineComponent({
    name: 'SelectJourneySetTemplate',
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonItem,
        IonLabel
    },
    props: {
        journeySetId: {
            type: String,
            required: true,
            default: ''
        }
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.journeySetCreation);
        await execute(async () => {
            this.templates = await getCompleteTemplates(this.store.getters['app/selectedAppInstanceId']);
        }, this.language.errors.gettingTemplates);
    },
    setup(props) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const templates = ref([] as JourneyTemplate[]);

        const selectTemplate = async (templateId: string) => {
            await execute(async () => {
                const editingExisting = props.journeySetId.trim().toLowerCase() !== 'create';
                if (editingExisting) {
                    const journeySet = await getJourneySet(props.journeySetId);
                    journeySet.templateId = templateId;
                    await updateJourneySet(journeySet);
                    await router.push({ name: 'journeySetCategory', params: { journeySetId: props.journeySetId }});
                } else {
                    await router.push({ name: 'journeySetCategory', params: { journeySetId: 'create', templateId: templateId }});
                }
            }, language.value.errors.savingJourneySet);
        }

        return {
            templates,
            selectTemplate,
            store,
            language,
            formatDate
        }
    }
});
